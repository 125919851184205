<template>
<div>
  <div class="h-screen flex items-center justify-center">
    <div class="text-center leading-none tracking-tight w-2/3">
      <div class="border-b-8 border-dashed pb-4 border-green-100 flex items-center justify-between">
        <img class=" w-auto h-20" src="https://upload.wikimedia.org/wikipedia/en/thumb/b/b2/Malaysia_Airports_Logo.svg/1200px-Malaysia_Airports_Logo.svg.png" >
      </div>
      <div class="flex items-center justify-center">
        <!-- ladies -->
        <div>
          <div class="w-full mt-4 flex items-center justify-center bg-gradient-to-l from-green-200 rounded-lg">
            <svg class=" w-60 h-60" viewBox="0 0 652.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M3092 12785 c-360 -65 -650 -295 -790 -625 -62 -144 -85 -274 -79
              -440 3 -93 11 -160 25 -216 81 -306 275 -548 550 -684 520 -256 1142 -45 1397
              475 63 129 91 226 104 362 35 381 -143 756 -459 965 -225 148 -499 208 -748
              163z"/>
              <path d="M1993 10475 c-379 -59 -696 -270 -923 -613 -107 -161 -79 -79 -490
              -1432 -162 -533 -338 -1112 -464 -1528 -53 -173 -101 -343 -107 -380 -41 -232
              81 -448 288 -512 161 -50 324 -5 436 119 84 94 72 60 377 1086 67 226 164 550
              215 720 50 171 145 489 210 708 l119 397 218 0 c181 0 218 -2 218 -14 0 -8
              -32 -128 -71 -268 -70 -251 -279 -1004 -824 -2963 -415 -1493 -425 -1528 -431
              -1547 -5 -17 30 -18 640 -18 l646 0 0 -1902 c0 -2072 -3 -1964 56 -2080 52
              -103 156 -188 276 -225 79 -26 245 -23 322 6 147 53 243 152 293 301 17 52 18
              144 21 1988 l2 1932 245 0 245 0 0 -1935 c0 -1933 0 -1936 21 -1998 63 -188
              213 -299 424 -314 253 -17 466 146 504 387 7 43 11 699 11 1953 l0 1887 640 0
              c417 0 640 3 640 10 0 5 -72 268 -161 582 -88 315 -200 715 -249 888 -49 173
              -161 574 -250 890 -275 980 -339 1207 -410 1460 -38 135 -114 406 -169 603
              -56 197 -101 362 -101 367 0 6 90 10 230 10 l229 0 21 -72 c12 -40 30 -100 40
              -133 10 -33 55 -184 100 -335 45 -151 120 -403 167 -560 46 -157 116 -390 154
              -517 146 -490 212 -714 265 -893 30 -102 65 -210 76 -240 83 -215 272 -336
              469 -300 227 41 374 244 356 490 -6 75 -14 104 -238 840 -200 657 -521 1714
              -606 1995 -43 144 -94 289 -119 342 -59 124 -169 267 -304 396 -246 236 -528
              378 -835 422 -139 20 -2295 20 -2422 0z"/>
              </g>
            </svg>
            <div class="w-full text-11xl font-extrabold">
              <div class="">
                8
              </div>
              <div class="border-4 border-gray-900"></div>
              <div>
                10
              </div>
            </div>
          </div>
          <div class="flex mt-4 items-center justify-center w-full py-4 bg-gradient-to-r from-green-500 to-teal-500 text-6xl text-white font-extrabold rounded-lg">
            <svg class="w-16 h-16 animate-pulse" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
            Available
            
        </div>
        </div>
        <div class="px-4"></div>
        <!-- gentlement -->
        <div>
          <div class="w-full mt-4 flex items-center justify-center bg-gradient-to-r from-red-200 rounded-lg">
            <div class="w-full text-11xl font-extrabold">
              <div>
                10
              </div>
              <div class="border-4 border-gray-900"></div>
              <div>
                10
              </div>
            </div>
              <svg class="w-60 h-60" viewBox="0 0 640.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#000000" stroke="none">
              <path d="M3027 12784 c-290 -52 -544 -220 -705 -463 -134 -204 -189 -425 -170
              -681 30 -386 296 -743 659 -886 143 -56 212 -68 389 -69 168 0 209 6 340 47
              263 83 515 309 630 562 124 273 129 581 13 856 -73 174 -231 368 -378 465
              -233 154 -520 216 -778 169z"/>
              <path d="M1920 10435 c-8 -2 -49 -9 -90 -15 -106 -17 -265 -71 -371 -126 -394
              -204 -653 -566 -731 -1024 -10 -59 -13 -445 -13 -1815 l0 -1740 22 -71 c71
              -223 311 -355 546 -300 161 38 267 129 328 281 l24 60 3 1553 2 1552 110 0
              110 0 2 -4152 3 -4153 21 -61 c59 -169 154 -284 295 -353 190 -93 392 -93 586
              0 152 73 269 220 314 394 10 40 14 536 16 2472 l3 2423 105 0 105 0 0 -2407
              c0 -2080 2 -2418 15 -2478 61 -293 341 -494 655 -471 260 18 457 165 538 401
              l27 80 3 4153 2 4153 108 -3 107 -3 5 -1555 c4 -1101 8 -1564 16 -1585 75
              -204 232 -315 447 -315 234 0 413 158 447 395 8 58 10 541 8 1770 -3 1588 -5
              1696 -22 1785 -110 572 -500 992 -1046 1128 l-105 26 -1290 2 c-709 1 -1297 1
              -1305 -1z"/>
              </g>
              </svg>
          </div>
          <div class="flex mt-4 items-center justify-center w-full py-4 bg-gradient-to-r from-pink-500 to-red-500 text-6xl text-white font-extrabold rounded-lg">
              Full
              <svg class="w-16 h-16 animate-pulse" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd"></path></svg>
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

